import React, { useEffect, useState } from "react";
import {ReactComponent as ArrowUpIcon} from '../images/icons/ArrowUpIcon.svg';

export default function BackToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <button
      className={`fixed bottom-8 right-8 text-3xl text-[#9B03D0] z-10 p-0 w-fit bg-white rounded-full drop-shadow-lg ${
        isVisible ? "opacity-100 scale-100" : "opacity-0 scale-0"
      } transition-all ease-in duration-300`}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      title="Back to top"
    >
      <ArrowUpIcon className="scale-105" />
    </button>
  );
}
