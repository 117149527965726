import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import BackToTop from "./components/BackToTop.js";
import React, { Suspense, useEffect } from "react";
import loadingAnimation from "./images/loadingAnimation.gif";

function App() {
  const Home = React.lazy(() => import("./pages/Home.js"));
  const PartnerWithUs = React.lazy(() => import("./pages/PartnerWithUs.js"));
  const HowItWorks = React.lazy(() => import("./pages/HowItWorks.js"));
  const HowItWorksForShopper = React.lazy(() =>
    import("./pages/HowItWorksForShopper.js")
  );
  // const VerifyEmail = React.lazy(() => import("./pages/VerifyEmail.js"));

  return (
    <div className="">
      <BackToTop />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <SuspenseComponent>
                <Home />
              </SuspenseComponent>
            }
          />
          <Route
            path="/get-in-touch"
            element={
              <SuspenseComponent>
                <PartnerWithUs />
              </SuspenseComponent>
            }
          />
          <Route
            path="/how-it-works-for-merchants"
            element={
              <SuspenseComponent>
                <HowItWorks />
              </SuspenseComponent>
            }
          />
          <Route
            path="/wordpress/strabl-1click-checkout"
            element={
              <SuspenseComponent>
                <HowItWorks />
              </SuspenseComponent>
            }
          />
          <Route
            path="/how-it-works-for-shoppers"
            element={
              <SuspenseComponent>
                <HowItWorksForShopper />
              </SuspenseComponent>
            }
          />
          {/* <Route path="/pricing" element={<Pricing />} /> */}
          <Route path="/blog" element={<RedirectToBlog />} />
          <Route path="/blog/*" element={<RedirectToBlog />} />
          {/* <Route path="/verify-email/:token" element={<VerifyEmail />} /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

function SuspenseComponent({ children }) {
  return (
    <Suspense
      fallback={
        <div className="flex flex-col justify-center items-center h-[80vh]">
          <img
            src={loadingAnimation}
            alt="Processing"
            className="w-28 h-28"
            width={694}
            height={694}
          />
        </div>
      }
    >
      {children}
    </Suspense>
  );
}

function RedirectToBlog() {
  const { pathname } = useLocation();
  const baseUrl = "https://strabl.io";

  useEffect(() => {
    if (pathname.includes(".html")) {
      window.location.href = `${baseUrl}${pathname}`;
    } else {
      // check if pathname ends with slash
      if (pathname.endsWith("/")) {
        window.location.href = `${baseUrl}${pathname}index.html`;
      } else {
        window.location.href = `${baseUrl}${pathname}/index.html`;
      }
    }
  }, [pathname]);

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <h1 className="text-lg font-kineticaBold animate-pulse">
        Redirecting to blog...
      </h1>
    </div>
  );
}
